import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        component: () => import("@/views/Login")
    },
    {
        path: '/ducts-fs',
        component: () => import("@/views/DuctsFS"),
    },
    {
        path: '/main',
        component: () => import("@/views/Main"),
        redirect: 'main/forecast',
        children: [
            {
                path: 'dashboard',
                component: () => import("@/components/pages/Main/Dashboard")
            },
            {
                path: 'profile',
                redirect: 'profile/view'
            },
            {
                path: 'profile/view',
                component: () => import("@/components/pages/Main/UserProfile/View"),
            },
            {
                path: 'forecast',
                component: () => import("@/components/pages/Main/WeeklyForecast"),
            },
            {
                path: 'forecast/custom-upload',
                component: () => import('@/components/pages/Main/WeeklyForecast/CustomUpload')
            },
            {
                path: 'order',
                redirect: 'file/upload'
            },
            {
                path: 'order/view',
                component: () => import("@/components/pages/Main/OrderTable/View"),
            },
            {
                path: 'version-log',
                component: () => import("@/components/pages/Main/VersionLog")
            },
            {
                path: 'charts',
                component: () => import("@/components/pages/Main/Chart"),
            },
            {
                path: 'charts/dashboard',
                component: () => import("@/components/pages/Main/Chart/Dashboard/index")
            }
        ]
    },
    {
        path: '/pg',
        component: () => import('@/views/Playground'),
        children: [
            {
                path: 'first',
                component: () => import('@/views/Playground/First')
            },
            {
                path: 'second',
                component: () => import('@/views/Playground/Second')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
